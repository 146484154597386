<template>
	<div />
</template>
<script>
import { admin } from '@/settings/Roles';
import { Dashboard as DashboardMessages } from '@/translations';
import Dashboard from '@/util/Dashboard';
import { S3_PATH } from '@/settings/Images';

export default {
	messages: [DashboardMessages],
	data() {
		return {
			admin,
			alert: new this.$Alert(),
			showVClubPopup: new Dashboard(),
			hideVClubPopup: new Dashboard(),
			themeName: process.env.VUE_APP_THEME,
		};
	},
	mounted() {
		this.showVClubPopup.canShowVClubPopup().then((response) => {
			this.showPopup(response.response.show_v_club_popup);
		});
	},
	methods: {
		showPopup(show) {
			if (show) {
				const trans = {
					title: this.translate('v_club_dashboard_introducing_title').toUpperCase(),
					text: this.translate('v_club_dashboard_introducing_text'),
				};
				const options = {
					config: {
						icon: undefined,
						showCancelButton: true,
						confirmButtonText: this.translate('i_want_it'),
						cancelButtonText: this.translate('i_dont_want_to_save_money'),
						allowOutsideClick: false,
						reverseButtons: true,
						allowEscapeKey: false,
						confirmButtonColor: '#eb772f',
						input: 'checkbox',
						customClass: {
							input: 'intro-popup-input',
							actions: 'intro-popup-actions',
							image: 'my-0',
						},
						inputPlaceholder: this.translate('dont_show_message_again'),
						imageUrl: `${S3_PATH}/assets/products/${this.themeName}/v_club_membership.png?v=1`,
						imageWidth: '130px',
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then((result) => {
					if (result.value) {
						this.hideVClubPopup.hideVClubPopup();
					}
					this.$router.push({ name: 'Renewal', query: { membership: 'v_club' } });
					this.$emit('done');
				}).catch(() => {
					this.$emit('done');
				});
			} else {
				this.$emit('done');
			}
		},
	},
};
</script>
<style>
/* These styles are not scoped due to sweet-alert-2 not having access to the vue-generated data- hash */
.intro-popup-input {
	margin-top: 1.875em;
	margin-bottom: 0;
}
.intro-popup-input span {
	font-size: 0.75em;
}
.intro-popup-actions {
	margin-top: 0.75em;
}
</style>
