<template>
	<div>
		<div class="row mb-4">
			<div class="col">
				<div class="card mb-0">
					<div class="card-header bg-dark">
						<h5 class="mb-0">
							{{ translate('leaderboard') }} {{ month !== '' ? `(${month})` : '' }}
							<a
								v-if="month !== ''"
								class="text-small text-primary pointer"
								@click="getDataByMonth">
								{{ translate(isCurrentMonth ? 'view_last_month' : 'view_current_month') }}
							</a>
						</h5>
					</div>
					<div class="card-body p-0">
						<b-row
							v-if="showUserId"
							class="px-3">
							<b-col
								md="6"
								sm="11">
								<div
									class="form-group">
									<label
										for="organization"
										class="label mt-2">{{ translate('organization') }}</label>
									<select
										id="organization"
										v-model="selectedOrganization"
										name="organization"
										class="form-control">
										<option
											value=""
											disabled
											selected>
											{{  translate('organization') }}
										</option>
										<option
											v-for="(leader, index) in leaders"
											:key="index"
											:value="leader.id">
											{{ leader.attributes.leader }}
										</option>
									</select>
								</div>
							</b-col>
							<b-col
								class="mt-3 pt-1"
								md="1"
								sm="12">
								<button
									class="btn btn-primary mt-3"
									:disabled="selectedOrganization === ''"
									@click="getDataFiltered">
									{{ translate('search') }}
								</button>
							</b-col>
						</b-row>
						<div class="table-responsive mb-0">
							<table class="table table-hover table-striped text-nowrap">
								<thead>
									<tr>
										<th>
											#
										</th>
										<th>
											{{ translate('country') }}
										</th>
										<th>
											{{ translate('name') }}
										</th>
										<th v-if="showUserId">
											{{ translate('user_id') }}
										</th>
										<th
											class="text-center pointer"
											@click="sortLeaderBoardByField('count_distributors')">
											{{ translate('new_distributors') }}
											<sort
												:import-data="sortData"
												field="count_distributors" />
										</th>
										<th
											class="text-center pointer"
											@click="sortLeaderBoardByField('count_customers')">
											{{ translate('new_customers_leaderboard') }}
											<sort
												:import-data="sortData"
												field="count_customers" />
										</th>
										<th
											class="text-right pointer"
											@click="sortLeaderBoardByField('sponsored_orders')">
											{{ translate('new_distributor_pbv') }}
											<sort
												:import-data="sortData"
												field="sponsored_orders" />
										</th>
										<th
											class="text-right pointer"
											@click="sortLeaderBoardByField('customer_orders')">
											{{ translate('new_customer_pbv') }}
											<sort
												:import-data="sortData"
												field="customer_orders" />
										</th>
										<th
											class="text-right pointer"
											@click="sortLeaderBoardByField('total')">
											{{ translate('total') }}
											<sort
												:import-data="sortData"
												field="total" />
										</th>
										<th
											class="text-right pointer"
											@click="sortLeaderBoardByField('new_pbv')">
											{{ translate('trip_points') }}
											<sort
												:import-data="sortData"
												field="new_pbv" />
										</th>
										<th
											v-if="showTripPoints"
											class="text-right">
											{{ translate('accumulated_trip_points') }}
										</th>
									</tr>
								</thead>
								<tbody v-if="!loading">
									<tr
										v-for="(item, index) in leaderboardData"
										:key="index"
										class="text-nowrap">
										<td class="align-middle">
											{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
										</td>
										<td class="align-middle">
											<img
												v-b-tooltip.hover
												class="ml-3"
												:title="translate(item.attributes.country_code.toLowerCase())"
												:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
												:alt="translate(item.attributes.country_code.toLowerCase())">
										</td>
										<td class="align-middle">
											{{ item.attributes.shortened_name }}
										</td>
										<td v-if="showUserId">
											<span
												class="badge badge-primary pointer"
												@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
												{{ item.attributes.user_id }}
											</span>
										</td>
										<td class="align-middle text-center">
											{{ item.attributes.new_distributors }}
										</td>
										<td class="align-middle text-center">
											{{ item.attributes.new_customers }}
										</td>
										<td class="align-middle text-right">
											{{ item.attributes.sponsored_orders }}
										</td>
										<td class="align-middle text-right">
											{{ item.attributes.customer_orders }}
										</td>
										<td class="align-middle text-right">
											{{ item.attributes.total_bvs }}
										</td>
										<td
											class="align-middle text-right">
											{{ item.attributes.new_total_bvs }}
										</td>
										<td
											v-if="showTripPoints"
											class="align-middle text-right">
											{{ item.attributes.accumulated_trip_points }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loading"
							:has-data="hasData" />
					</div>
				</div>
			</div>
		</div>
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import {
	DESC_ORDER as desc,
	ORDER_FIELD_NAME as field,
	ORDER_DIR_NAME as dir,
	PAGINATION,
} from '@/settings/RequestReply';
import {
	Dashboard as DashboardTranslations,
	PersonalBusinessVolume as PBV,
	Countries,
	Grids,
} from '@/translations';
import Dashboard from '@/util/Dashboard';
import Sort from '@/components/SortArrow';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { admin } from '@/settings/Roles';
import Leaders from '@/util/Leaders';

export default {
	name: 'DistributorLeaderboard',
	messages: [DashboardTranslations, PBV, Countries, Grids],
	components: {
		Sort,
	},
	mixins: [DashboardRedirect],
	data() {
		return {
			leaderboard: new Dashboard(),
			sortData: {},
			admin,
			isCurrentMonth: true,
			selectedOrganization: '',
			leadersData: new Leaders(),
			leaders: [],
		};
	},
	computed: {
		leaderboardData() {
			try {
				const { data } = this.leaderboard.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		loading() {
			return !!this.leaderboard.data.loading;
		},
		pagination() {
			return this.leaderboard.data.pagination;
		},
		hasData() {
			const response = this.leaderboardData.length;
			return !!response;
		},
		month() {
			try {
				const { start } = this.leaderboard.data.response.data.meta.period_dates;
				return this.$moment(start.date).format('MMMM');
			} catch (err) {
				return '';
			}
		},
		showTripPoints() {
			try {
				const { show_accumulated_trip_points: showTripPoints } = this.leaderboard.data.response.data.meta;
				return showTripPoints;
			} catch (e) {
				return false;
			}
		},
		showUserId() {
			return this.isAdmin && this.$route.name === 'AdminDashboard';
		},
		isAdmin() {
			return admin.includes(this.$user.details().type);
		},
	},
	mounted() {
		if (!this.isAdmin || this.$route.name !== 'AdminDashboard') {
			this.selectedOrganization = this.$route.params.distributorId;
			this.leaderboard.getLeaderboard({ organization: this.selectedOrganization });
		} else if (this.isAdmin) {
			this.leadersData.getLeaders(true).then((response) => {
				this.leaders = response;
			});
		}
	},
	methods: {
		sortLeaderBoardByField(fieldName) {
			const { query } = this.$route;
			delete query.month;
			if (!this.isCurrentMonth) {
				query.month = 'last_month';
			}

			// assign the sort field (i.e. username)
			query[field] = fieldName;
			// assign order direction
			query[dir] = desc;
			this.sortData = { ...query };
			if (this.isAdmin) {
				query.organization = this.selectedOrganization;
			}

			this.leaderboard.getLeaderboard(query);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			delete query.month;
			if (!this.isCurrentMonth) {
				query.month = 'last_month';
			}

			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			} if (this.isAdmin) {
				options.organization = this.selectedOrganization;
			}
			this.leaderboard.getLeaderboard(options);
		},
		getDataByMonth() {
			if (this.isCurrentMonth) {
				this.isCurrentMonth = false;
				const options = {
					month: 'last_month',
				};

				if (this.isAdmin) {
					options.organization = this.selectedOrganization;
				}

				this.leaderboard.getLeaderboard(options);
			} else {
				this.isCurrentMonth = true;
				if (this.isAdmin) {
					this.leaderboard.getLeaderboard({ organization: this.selectedOrganization });
				} else {
					this.leaderboard.getLeaderboard();
				}
			}
		},
	},
};
</script>
